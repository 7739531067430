@font-face {
    font-family: 'anssang';
    src: local('anssang'), url('./fonts/anssang.ttf') format('truetype');
    font-weight: normal;
}

* {
    padding: 0;
    margin: 0;
}

body,
html {
    /* background-color: black; */
    font-family: 'Noto Sans KR', sans-serif;
}

input:focus {
    outline: none;
}

input::-webkit-input-placeholder {
    color: white;
}
input:-ms-input-placeholder {
    color: white;
}
textarea::-webkit-input-placeholder {
    color: white;
}
textarea:-ms-input-placeholder {
    color: white;
}

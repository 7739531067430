.footer-wrap {
    min-height: 100px;
    background-color: #212529;
    display: flex;
    align-items: center;

    .footer {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #212529;
        box-sizing: border-box;
        color: white;
        .footer-title {
            display: block;
            font-weight: normal;
            font-size: 15px;
        }
        .footer-description {
            display: block;
            font-weight: lighter;
            padding-top: 3px;
            font-size: 12px;
        }
    }
}

// 공지사항
.news {
    box-sizing: border-box;
    max-width: 1300px;
    // padding-top: 100px;
    // padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: -0.4px;
    margin: 0 auto;
    margin-top: 59px;
    &-title {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 38px;
    }
    &-list {
        .news-content {
            width: 100%;
            transition: all 1s;
            &-text {
                padding: 19px 0;
                border-bottom: 1px solid #eaebef;
                .news-content-title {
                    display: flex;
                    justify-content: space-between;
                    text-align: center;
                    align-items: center;
                    margin-bottom: 16px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 15px;
                    color: #232323;
                    .news-content-btn {
                        width: 14px;
                        .news-content-btn-img {
                            width: 100%;
                        }
                    }
                }
                .news-content-description {
                    white-space: pre;
                    margin-bottom: 12px;
                    font-size: 12px;
                }
                .news-content-date {
                    font-size: 12px;
                    letter-spacing: -0.32px;
                    color: #b3b3b3;
                    .news-content-bar {
                        margin: 0 12px;
                    }
                }
            }
        }
    }
    .pagination {
        margin: 40px 0 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        list-style: none;
        font-size: 12px;
        cursor: pointer;
        .pagination-item-select {
            min-width: 26px;
            color: #3f474d;
            text-decoration: none;
            font-weight: 700;
        }
        .pagination-item-non-select {
            min-width: 26px;
            color: #828c94;
            text-decoration: none;
            font-weight: 400;
        }
    }
}

.navigation-wrap {
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
}
.navigation {
    width: 100%;
    padding: 15px 30px 15px 30px;
    display: flex;
    align-items: center;
    font-size: 17px;
    box-sizing: border-box;
    // max-width: 1195px;
    justify-content: space-between;
    .navigation-logo {
        width: 250px;
    }
    .navigation-menu {
        padding-right: 80px;
        .navigation-menu-item {
            margin-left: 50px;
            color: #212529;
            letter-spacing: -0.3px;
            cursor: pointer;
            &:hover {
                font-weight: bold;
            }
        }
    }
    .navigation-menu-icon-wrap {
        width: 25px;
        .navigation-menu-icon {
            width: 100%;
        }
    }
}

.navigation-menu-mobile {
    width: 100%;
    box-sizing: border-box;
    display: block;
    // padding-bottom: 15px;
    transition: height 3s ease in;
    &-item {
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 16px;
        color: #3b3b46;
        font-weight: normal;
        &:hover {
            font-weight: bold;
            color: #212529;
        }
    }
}
.menu-clicked {
    transition: height 3s ease in;
    max-height: 400px;
}
.menu-noclicked {
    transition: height 3s ease in;
    max-height: 0px;
}

.mainMessage {
    width: 100%;
    box-sizing: border-box;
    max-width: 1175px;
    margin: 0 auto;
    padding-top: 150px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    display: block;
    font-size: 40px;
    font-weight: bold;
    color: #212529;
}

.mainDescription {
    width: 100%;
    box-sizing: border-box;
    max-width: 1175px;
    margin: 0 auto;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    display: block;
    font-size: 20px;
    font-weight: normal;
    color: #212529;
    // white-space: pre;
}

.mainMessage-mobile {
    width: 100%;
    box-sizing: border-box;
    max-width: 1175px;
    margin: 0 auto;
    padding-top: 150px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    display: block;
    font-size: 25px;
    font-weight: bold;
    color: #212529;
}

.mainDescription-mobile {
    width: 100%;
    box-sizing: border-box;
    max-width: 1175px;
    margin: 0 auto;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    display: block;
    font-size: 15px;
    font-weight: normal;
    color: #212529;
    // white-space: pre;
}

//card
@mixin clearfix() {
    &:before,
    &:after {
        content: ' '; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}
$card-width: 365px;
$card-gap: 20px;
$card-total-width: $card-width + $card-gap;
$cards-quantity: 8;
$cards-speed: 100s;
$card-height: 300px;
.cards {
    overflow: hidden;
    width: 100%;
    // max-width: 375px;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 23px;

    .cards__list {
        display: flex;
        align-items: stretch;
        align-content: center;
        align-self: center;
        width: $card-total-width * $cards-quantity;
        padding: 20px 0 20px 0;

        @include clearfix;

        &:hover .card {
            //-webkit-animation-play-state: paused;
            //-moz-animation-play-state: paused;
            animation-play-state: paused;
        }

        li {
            float: left;
        }
    }
    .card {
        width: $card-width;
        height: $card-height;
        animation-duration: $cards-speed;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        text-align: center;
        margin: 0 $card-gap 0 0;
        // $card-padding: 20px;
        overflow: hidden;
        background: #fff;
        // padding: $card-padding;
        box-sizing: border-box;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        padding-left: 12px;
        padding-right: 12px;
        & > a {
            color: inherit !important;
        }
        &-link {
        }
        &-title {
            width: 100%;
            height: $card-height;
            padding-top: 10px;
            padding-left: 10px;
            text-align: left;
            // justify-content: center;
            // align-items: center;
            font-weight: normal;
        }
        &-title-comment {
            //   width: 100px;
            //   overflow: hidden;
            //   text-overflow: ellipsis;
            font-size: 14px;
            padding: 4px;
            white-space: pre-line;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
        }
        &-title-name {
            //   width: 100px;
            //   overflow: hidden;
            //   text-overflow: ellipsis;
            font-size: 16px;
        }
        &-people {
            position: relative;
            top: 33px;
            width: 100%;
            text-align: right;
            font-size: 12px;
        }
        &-img-wrap {
            width: 11px;
            display: inline-block;
            margin-right: 4px;
        }
        &-img {
            width: 100%;
        }
    }
}
// Creating animations for each cards
$i: $cards-quantity;
@while $i > 0 {
    #slide-card-#{$i} {
        animation-name: cardslide-#{$i};
        overflow: visible;
    }

    @keyframes cardslide-#{$i} {
        // Creating percentages based on cards quantity
        $j: $cards-quantity;
        $moved: false;
        @while $j > 0 {
            $percentage: (100 / $cards-quantity) * ($cards-quantity - $j);

            @if $moved == false {
                #{$percentage}% {
                    transform: translateX(
                        -$card-total-width * ($cards-quantity - $j)
                    );
                }
                @if ($cards-quantity - $j) == $i {
                    #{$percentage + 0.01}% {
                        transform: translateX(
                            $card-total-width *
                                ($cards-quantity - ($cards-quantity - $j))
                        );
                    }
                    $moved: true;
                }
            } @else {
                #{$percentage}% {
                    transform: translateX($card-total-width * $j);
                }
            }

            $j: $j - 1;
        }
        @if $i == $cards-quantity {
            99.99% {
                transform: translateX(-$cards-quantity * $card-total-width);
            }
        }

        100% {
            transform: translateX(0);
        }
    }

    $i: $i - 1;
}

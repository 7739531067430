.main {
    background: url('https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F5.jpg?alt=media&token=5b7c5a69-99dd-4708-8676-31afe9930876')
        no-repeat fixed;
    background-position: 50% 0%;
    // background-repeat: no-repeat;
    margin-top: 60px;
    background-size: cover;
    padding-top: 160px;
    padding-bottom: 500px;
    .main-wrap {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;

        box-sizing: border-box;
        // padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
        .main-title {
            font-weight: bold;
            font-size: 50px;
        }
        .main-description {
            font-size: 20px;
            line-height: 32px;
            white-space: pre-wrap;
            margin-top: 20px;
            margin-bottom: 60px;
        }
        .main-btn {
            width: 335px;
            height: 64px;
            text-align: center;
            line-height: 64px;
            font-size: 18px;
            font-weight: bold;
            color: white;
            background-color: #eb6776;
            border-radius: 4px;
            box-shadow: 0 4px 14px 0 rgba(203, 103, 118, 0.8);
            cursor: pointer;
        }
    }
}


.main-mobile {
    // background: url('https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F5.jpg?alt=media&token=5b7c5a69-99dd-4708-8676-31afe9930876')
    //     no-repeat fixed;
    // background-position: 50% 0%;
    // background-repeat: no-repeat;
    margin-top: 60px;
    background-size: cover;
    padding-top: 160px;
    // padding-bottom: 500px;
    .main-mobile-wrap {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;

        box-sizing: border-box;
        // padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
        .main-mobile-title {
            font-weight: bold;
            font-size: 40px;
        }
        .main-mobile-description {
            background-color: rgba(0, 0, 0, 0.3);
            color: white;
            border-radius: 10px;
            font-size: 15px;
            line-height: 32px;
            white-space: pre-wrap;
            margin-top: 20px;
            margin-bottom: 60px;
            padding-top: 30px;
            padding-right: 10px;
            padding-left: 10px;
            padding-bottom: 30px;
        }
        .main-btn {
            width: 335px;
            height: 64px;
            text-align: center;
            line-height: 64px;
            font-size: 18px;
            font-weight: bold;
            color: white;
            background-color: #eb6776;
            border-radius: 4px;
            box-shadow: 0 4px 14px 0 rgba(203, 103, 118, 0.8);
            cursor: pointer;
        }
    }
}



@import './photos.scss';
@import './navigation.scss';
@import './main.scss';
@import './news.scss';
@import './footer.scss';
@import './mainMessage.scss';
@import './font.scss';

a {
    text-decoration: none;
}
a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
